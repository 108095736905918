// Import necessary hooks and components
import React, { useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Books } from "../../../Assets/books";
import { Chapter } from "../../Chapter/Chapter";
import "../../Main/Main.css";
import { GrTwitter as ShareBtn } from "react-icons/gr";
import { FaBookBible as ChapterBtn } from "react-icons/fa6";
import { IoHome as HomeBtn } from "react-icons/io5";
// Import the local JSON file
import YLTData from "../../../Assets/YLT.json";
import { ErrorPage } from "../../ErrorPage/ErrorPage";

const fetchVerseByPK = async (pk) => {
  const dataArray = Object.values(YLTData); // Convert the data object to an array
  const selectedVerse = dataArray.find((v) => v.pk === pk);
  if (selectedVerse) {
    return selectedVerse;
  } else {
    throw new Error("No verse found with the generated pk");
  }
};

export const Index = () => {
  const { pk } = useParams();
  const navigate = useNavigate();
  const paragraphRef = useRef(null);

  const {
    data: verse,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["verse", pk],
    queryFn: () => fetchVerseByPK(parseInt(pk)),
    refetchOnWindowFocus: false, // Disable refetch on window focus
    refetchOnMount: true, // Enable refetch on mount to load the verse initially
    onError: (err) => {
      console.error("Query error:", err);
    },
  });

  useEffect(() => {
    if (paragraphRef.current) {
      const paragraphLength = paragraphRef.current.innerText.length;
      if (paragraphLength > 146) {
        paragraphRef.current.id = "scaledown";
      } else if (paragraphLength > 400) {
        paragraphRef.current.id = "scaledown2";
      } else {
        paragraphRef.current.id = "";
      }
    }
  }, [verse]);

  const handleShareClick = () => {
    if (verse) {
      const urlToCopy = `${window.location.origin}/read/${verse.pk}`;
      navigator.clipboard
        .writeText(urlToCopy)
        .then(() => {
          alert("Link copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy link: ", err);
        });
    }
  };

  const openModal = () => {
    document.getElementById("my_modal_4").showModal();
  };

  if (error) return <ErrorPage text={error.message} />;

  return (
    <>
      <div className="quote-wrapper">
        <blockquote className="text text-color">
          <p ref={paragraphRef}>
            {isLoading ? (
              <span className="loading loading-ring loading-md"></span>
            ) : (
              verse?.text
            )}
          </p>
          <footer>
            {isLoading ? (
              <span className="loading loading-ring loading-sm"></span>
            ) : (
              `- ${Books[verse.book]}. ${verse.chapter}:${verse.verse}`
            )}
            {isLoading ? (
              <span></span>
            ) : (
              <div className="icons">
                <ShareBtn onClick={handleShareClick} />
              </div>
            )}
          </footer>
        </blockquote>
      </div>
      <div className="container mx-auto flex justify-center mt-10">
        <div className="join">
          <button className="join-item btn btn-disabled">«</button>
          <button
            className="join-item btn ml-2"
            onClick={() => navigate("/read")}
          >
            Next Verse<span className="pl-4">»</span>
          </button>
        </div>
      </div>
      <footer className="my-2">
        <ul className="menu menu-horizontal bg-base-200 rounded-box">
          <li>
            <button
              className="btn"
              onClick={openModal}
            >
                <ChapterBtn className="h-5 w-5" />
                <Chapter verse={verse} />
            </button>
          </li>
          <li>
            <button
              className="btn mx-2"
              onClick={() => navigate("/")}
            >
              <HomeBtn className="h-5 w-5" />
            </button>
          </li>
        </ul>
      </footer>
    </>
  );
};
