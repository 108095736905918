import YLTData from "../../Assets/YLT.json"; // Ensure the correct path
import React, { useEffect, useState } from "react";
import { Books } from "../../Assets/books";

export const Chapter = ({ verse }) => {
  const [chapterText, setChapterText] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (verse) {
      setLoading(true);
      const loadChapter = async () => {
        try {
          // Fetch chapter data from the local JSON
          const data = YLTData.filter(
            (item) => item.book === verse.book && item.chapter === verse.chapter
          );

          // Generate the chapter text with the highlighted verse
          const textContent = data
            .map((item) => {
              if (item.verse === verse.verse) {
                return `<strong>${item.text}</strong>`;
              }
              return item.text;
            })
            .join(" ");

          setChapterText(textContent);
          setLoading(false);
        } catch (error) {
          console.error("Error loading chapter:", error);
        }
      };

      loadChapter();
    }
  }, [verse]);

  if (!verse) {
    return null;
    // return null; // Or a loading indicator
  }

  return (
      <dialog
        id="my_modal_4"
        className="modal"
      >
        {/* if there is a button in form, it will close the modal */}
        <div className="modal-box w-11/12 max-w-5xl prose">
          <form method="dialog">
            
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
              ✕
            </button>
          </form>
          <h3 className="font-bold text-lg text-left font-serif ">
            {Books[verse.book]}. {verse.chapter}:{verse.verse}
          </h3>
          {loading ? (
            <span className="loading loading-dots loading-lg"></span>
          ) : (
            <p
              className="py-4 text-left font-serif font-normal"
              dangerouslySetInnerHTML={{ __html: chapterText }}
            ></p>
          )}
          <div className="modal-action">
            <form method="dialog">
              <button className="btn">Close</button>
            </form>
          </div>
        </div>
      </dialog>
  );
};
