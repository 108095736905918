import React from "react";
import { useNavigate } from "react-router-dom";

export const ErrorPage = ({ text = "The page you are looking for does not exist or an error occurred." }) => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <div className="hero">
      <div className="hero-content text-base-content text-center">
        <div className="max-w-md">
          <h1 className="text-5xl font-bold">Oops! Page Not Found</h1>
          <p className="py-6">
            {text}
          </p>
          <button
            className="btn btn-primary"
            onClick={handleGoHome}
          >
            Go to Home
          </button>
        </div>
      </div>
    </div>
  );
};
