import React, { useState, useEffect, useRef } from "react";
import "../Main.css";  // Ensure to import the correct stylesheet
import { Books } from "../../../Assets/books";
import { ref, get, runTransaction } from "firebase/database";
import { database } from "../../../API/firebase";
import { GrTwitter as ShareBtn } from "react-icons/gr";
import { FaHeart as LikeBtn } from "react-icons/fa6";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Pagination } from "../../Utils/Pagination";
import { Menu } from "../../Utils/Menu";
import { ErrorPage } from "../../ErrorPage/ErrorPage";
import Alert from "../../Utils/Alert";

const handleLikePress = (index, isLiked) => {
  const likesRef = ref(database, `verses/${index}/likes`);
  runTransaction(likesRef, (currentLikes) => {
    return isLiked ? (currentLikes || 0) - 1 : (currentLikes || 0) + 1;
  });
};

const fetchVerseByIndex = async (index) => {
  const dbRef = ref(database, `verses/${index}`);
  const snapshot = await get(dbRef);
  if (snapshot.exists()) {
    return snapshot.val();
  } else {
    console.error(`No verse found for index: ${index}`); // Log an error if verse not found
    throw new Error("Verse not available");
  }
};

const fetchVersesCount = async () => {
  const dbRef = ref(database, "verses");
  const snapshot = await get(dbRef);
  if (snapshot.exists()) {
    const data = snapshot.val();
    return Object.keys(data).length; // Correctly count the number of verses
  } else {
    throw new Error("No data available");
  }
};

export const Date = () => {
  const { index } = useParams();
  const navigate = useNavigate();
  const [likes, setLikes] = useState(null);
  const [likedVerses, setLikedVerses] = useState({});
  const paragraphRef = useRef(null);

  const { data: verse, isLoading, error } = useQuery({
    queryKey: ["verse", index],
    queryFn: () => fetchVerseByIndex(index),
    //staleTime: 1000 * 60 * 5,
  });

  const { data: versesCount } = useQuery({
    queryKey: ["versesCount"],
    queryFn: fetchVersesCount,
    //staleTime: 1000 * 60 * 5,
  });

  useEffect(() => {
    if (verse) {
      setLikes(verse.likes);
    }
  }, [verse]);

  useEffect(() => {
    if (paragraphRef.current && verse) {
      const paragraphLength = paragraphRef.current.innerText.length;
      paragraphRef.current.id =
        paragraphLength > 146
          ? "scaledown"
          : paragraphLength > 400
          ? "scaledown2"
          : "";
    }
  }, [verse]);

  useEffect(() => {
    if (verse) {
      const isLiked = sessionStorage.getItem(`liked_${verse.pk}`) === "true";
      setLikedVerses((prevState) => ({
        ...prevState,
        [verse.pk]: isLiked,
      }));
    }
  }, [verse]);

  const handleClick = (index, pk) => {
    const isLiked = sessionStorage.getItem(`liked_${pk}`) === "true";
    const newLikes = isLiked ? likes - 1 : likes + 1;
    setLikedVerses((prevState) => ({
      ...prevState,
      [pk]: !isLiked,
    }));
    setLikes(newLikes);
    handleLikePress(index, isLiked);
    sessionStorage.setItem(`liked_${pk}`, !isLiked);
  };

  const openAlert = () => {
    document.getElementById('my_modal_5').showModal();
  };

  const handleShareClick = () => {
    if (verse) {
      const urlToCopy = `${window.location.origin}/verse/${index}`;
      navigator.clipboard
        .writeText(urlToCopy)
        .then(() => {
          openAlert();
        })
        .catch((err) => {
          console.error("Failed to copy link: ", err);
        });
    }
  };

  const openNextPost = () => {
    const nextIndex = parseInt(index) + 1;
    if (nextIndex < versesCount) {
      navigate(`/verse/${nextIndex}`);
    } else { navigate("/"); }
  };

  const openPreviousPost = () => {
    const prevIndex = parseInt(index) - 1;
    if (prevIndex >= 0) {
      navigate(`/verse/${prevIndex}`);
    } else { navigate("/"); }
  };

  if (error) return <ErrorPage text={error.message} />;

  return (
    <>
      <div className="quote-wrapper">
        <blockquote className="text">
          {isLoading ? (
            <span></span>
          ) : (
            <>
              <p ref={paragraphRef}>{verse?.text}</p>
              <footer>
                - {Books[verse?.book]}. {verse?.chapter}:{verse?.verse}
                <div className="icons">
                  <ShareBtn onClick={handleShareClick} />
                  <Alert />
                  <LikeBtn
                    style={{
                      color: likedVerses[verse?.pk] ? "#FF00FF" : "white",
                      fontSize: likedVerses[verse?.pk] ? "20px" : "17px",
                    }}
                    onClick={() => handleClick(index, verse?.pk)}
                  />
                </div>
              </footer>
            </>
          )}
        </blockquote>
      </div>
      <Pagination
        currentIndex={parseInt(index)}
        verse={verse}
        maxIndex={versesCount - 1}
        onNext={openNextPost}
        onPrevious={openPreviousPost}
      />
      <Menu
        verse={verse}
        likes={likes}
        isClicked={likedVerses[verse?.pk]}
        handleClick={() => handleClick(index, verse?.pk)}
      />
    </>
  );
};
