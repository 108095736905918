import React from "react";
import { GiPerspectiveDiceSixFacesRandom as RandomBtn } from "react-icons/gi";
import { Link } from "react-router-dom";
import { Chapter } from "../Chapter/Chapter";
import { FaBookBible as ChapterBtn } from "react-icons/fa6";

export const Menu = ({ verse, handleClick, likes }) => {
  const openModal = () => {
    document.getElementById("my_modal_4").showModal();
  };

  return (
    <div className="container flex mx-auto my-2 justify-center">
      <ul className="menu menu-horizontal bg-base-200 rounded-box items-center">
        <li>
          <button
            className="btn mr-2"
            onClick={openModal}
          >
              <ChapterBtn className="h-5 w-5" />
              <Chapter verse={verse} />
          </button>
        </li>
        <li>
          <Link
            to="/read"
            className="btn"
          >
            <RandomBtn className="h-7 w-7" />
          </Link>
        </li>

        <li>
          <button
            className="btn"
            onClick={handleClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
              />
            </svg>
            <div className="badge badge-info">+{likes}</div>
          </button>
        </li>
      </ul>
    </div>
  );
};
