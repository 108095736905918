import React from "react";
import { Link } from "react-router-dom";
import { ThemeController } from "../Utils/ThemeController";
//import { FaFire } from "react-icons/fa";

export const Header = () => {
  return (
    <div className="navbar bg-base-100">
      <div className="flex-1">
        <Link to="/">
          <button className="btn btn-ghost border-0 text-xl bg-transparent text-base-content">Sacred Text Today</button>
        </Link>

        {/* <Link
          to="/fire"
          className="btn"
        >
          <FaFire />
        </Link> */}
      </div>
      <div className="flex-none">
        <ThemeController />
        {/* <button className="btn btn-square btn-ghost">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className="inline-block h-5 w-5 stroke-current"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
            ></path>
          </svg>
        </button> */}
      </div>
    </div>
  );
};
