import "./App.css";
import { Footer } from "./Components/Footer/Footer";
import { Header } from "./Components/Header/Header";
import { ErrorPage } from "./Components/ErrorPage/ErrorPage";
import { Main } from "./Components/Main/Main";
import { Date } from "./Components/Main/Date/Date";
import { Read } from "./Components/Read/Read";
import { Index } from "./Components/Read/Index/Index";
import { BrowserRouter, Routes, Route } from "react-router-dom"

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route exact path="/" element={<Main/>} />
        <Route path="/verse/:index" element={<Date />}/>
        <Route path="/read" element={<Read/>} />
        <Route path="/read/:pk" element={<Index/>} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
