import React from "react";

const displayDate = (timestamp) => {
  const date = new Date(timestamp * 1000); // Convert from seconds to milliseconds
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export const Pagination = ({ currentIndex, verse, maxIndex, onNext, onPrevious }) => {
  
  const displayIndex = verse ? displayDate(verse.date) : <div className="skeleton h-4 w-28"></div>; 

  return (
    <div className="container mx-auto flex justify-center mt-10">
      <div className="join">
        <button
          className="join-item btn"
          onClick={onPrevious}
          disabled={currentIndex === 0}
        >
          «
        </button>
        <button className="join-item btn">{displayIndex}</button>
        <button
          className="join-item btn"
          onClick={onNext}
          disabled={currentIndex >= maxIndex}
        >
          »
        </button>
      </div>
    </div>
  );
};
