import React from "react";

const Alert = () => {




  return (
    <dialog
      id="my_modal_5"
      className="modal modal-bottom sm:modal-middle"
    >
      <div className="modal-box bg-base-300">
        <h3 className="font-bold text-lg text-base-content not-italic">Link copied to clipboard!</h3>
        <p className="py-4"></p>
        <div className="modal-action">
          <form method="dialog">
            {/* if there is a button in form, it will close the modal */}
            <button className="btn">Close</button>
          </form>
        </div>
      </div>
    </dialog>
  );
};

export default Alert;

/* Open the modal using document.getElementById('ID').showModal() method
<button className="btn" onClick={()=>document.getElementById('my_modal_5').showModal()}>open modal</button> */