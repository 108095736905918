import "../Main/Main.css";
import React, { useEffect, useRef, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Books } from "../../Assets/books";
import { Chapter } from "../Chapter/Chapter";
import { useNavigate } from "react-router-dom";
import { BsHouseDoorFill as HomeBtn } from "react-icons/bs";
import { GrTwitter as ShareBtn } from "react-icons/gr";
import { FaBookBible as ChapterBtn } from "react-icons/fa6";
import YLTData from "../../Assets/YLT.json";
import Alert from "../Utils/Alert";
import { ErrorPage } from "../ErrorPage/ErrorPage";

const fetchRandomVerse = async () => {
  const dataArray = Object.values(YLTData);
  const minPk = 23215;
  const maxPk = 54306;
  const randomPk = Math.floor(Math.random() * (maxPk - minPk + 1)) + minPk;
  const selectedVerse = dataArray.find((v) => v.pk === randomPk);
  if (selectedVerse) {
    let verseHistory = JSON.parse(sessionStorage.getItem("verseHistory")) || [];
    verseHistory.push(selectedVerse.pk);
    sessionStorage.setItem("verseHistory", JSON.stringify(verseHistory));
    return selectedVerse;
  } else {
    throw new Error("No verse found with the generated id");
  }
};

const fetchVerseByPK = async (pk) => {
  const dataArray = Object.values(YLTData);
  const selectedVerse = dataArray.find((v) => v.pk === pk);
  if (selectedVerse) {
    return selectedVerse;
  } else {
    throw new Error("No verse found with the given pk");
  }
};

export const Read = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const paragraphRef = useRef(null);
  const [showPreviousButton, setShowPreviousButton] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);

  const {
    data: verse,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: ["randomVerse"],
    queryFn: fetchRandomVerse,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  useEffect(() => {
    const history = JSON.parse(sessionStorage.getItem("verseHistory")) || [];
    if (history.length <= 1) {
      setShowPreviousButton(false);
    } else {
      setShowPreviousButton(true);
    }
  }, [verse]);

  useEffect(() => {
    if (!hasFetched && !verse) {
      refetch().finally(() => setHasFetched(true));
    }
    if (verse) {
      const history = JSON.parse(sessionStorage.getItem("verseHistory")) || [];
      if (!history.includes(verse.pk)) {
        history.push(verse.pk);
        sessionStorage.setItem("verseHistory", JSON.stringify(history));
      }
    }
  }, [hasFetched, refetch, verse]);

  useEffect(() => {
    if (paragraphRef.current) {
      const paragraphLength = paragraphRef.current.innerText.length;
      if (paragraphLength > 146) {
        paragraphRef.current.id = "scaledown";
      } else if (paragraphLength > 400) {
        paragraphRef.current.id = "scaledown2";
      } else {
        paragraphRef.current.id = "";
      }
    }
  }, [verse]);

  const handleNextClick = () => {
    refetch().then(() => {
      const history = JSON.parse(sessionStorage.getItem("verseHistory")) || [];
      if (history.length > 1) {
        setShowPreviousButton(true);
      }
    });
  };

  const handleGoBackwardsClick = async () => {
    const history = JSON.parse(sessionStorage.getItem("verseHistory")) || [];
    if (history.length > 1) {
      history.pop(); // Remove the current verse
      const previousPk = history[history.length - 1];
      const previousVerse = await fetchVerseByPK(previousPk);
      sessionStorage.setItem("verseHistory", JSON.stringify(history));
      queryClient.setQueryData(["randomVerse"], previousVerse);

      if (history.length === 1) {
        setShowPreviousButton(false);
      }
    } else {
      console.warn("No previous verses in history");
    }
  };

  const openAlert = () => {
    document.getElementById('my_modal_5').showModal();
  };

  const handleShareClick = () => {
    if (verse) {
      const urlToCopy = `${window.location.origin}/read/${verse.pk}`;
      navigator.clipboard
        .writeText(urlToCopy)
        .then(() => {
          openAlert();
        })
        .catch((err) => {
          console.error("Failed to copy link: ", err);
        });
    }
  };

  const openModal = () => {
    document.getElementById("my_modal_4").showModal();
  };

  if (error) return <ErrorPage text={error.message} />;

  return (
    <>
      <div className="quote-wrapper">
        <blockquote className="text text-color">
          <p ref={paragraphRef}>
            {isLoading ? (
              <span className="loading loading-ring loading-md"></span>
            ) : (
              verse?.text
            )}
          </p>
          <footer>
            {isLoading ? (
              <span className="loading loading-ring loading-sm"></span>
            ) : (
              `- ${Books[verse.book]}. ${verse.chapter}:${verse.verse}`
            )}
            {isLoading ? (
              <span></span>
            ) : (
              <div className="icons">
                <ShareBtn onClick={handleShareClick} />
                <Alert />
              </div>
            )}
          </footer>
        </blockquote>
      </div>
      <div className="container mx-auto flex justify-center mt-10">
        <div className="join">
          <button
            className={`join-item btn ${
              !showPreviousButton ? "btn-disabled" : ""
            }`}
            onClick={handleGoBackwardsClick}
            disabled={!showPreviousButton}
          >
            «
          </button>

          <button
            className="join-item btn ml-2"
            onClick={handleNextClick}
          >
            Next Verse<span className="pl-4">»</span>
          </button>
        </div>
      </div>
      <footer className="my-2">
        <ul className="menu menu-horizontal bg-base-200 rounded-box">
          <li>
            <button
              className="btn mx-1"
              onClick={openModal}
            >
                <ChapterBtn className="h-5 w-5" />
                <Chapter verse={verse} />
            </button>
          </li>
          <li>
            <button
              className="btn mx-1"
              onClick={() => navigate("/")}
            >
              <HomeBtn className="h-5 w-5" />
            </button>
          </li>
        </ul>
      </footer>
    </>
  );
};
