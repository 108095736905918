import React, { useState, useEffect, useRef } from "react";
import "./Main.css";
import { Books } from "../../Assets/books";
import { ref, get, runTransaction } from "firebase/database";
import { database } from "../../API/firebase";
import { GrTwitter as ShareBtn } from "react-icons/gr";
import { FaHeart as LikeBtn } from "react-icons/fa6";
import { useQuery } from "@tanstack/react-query";
import { Pagination } from "../Utils/Pagination";
import { Menu } from "../Utils/Menu";
import { ErrorPage } from "../ErrorPage/ErrorPage";
import Alert from "../Utils/Alert";

const handleLikePress = (index, isLiked) => {
  const likesRef = ref(database, `verses/${index}/likes`);
  runTransaction(likesRef, (currentLikes) => {
    return isLiked ? (currentLikes || 0) - 1 : (currentLikes || 0) + 1;
  });
};

const fetchVerse = async (index) => {
  const dbRef = ref(database, `verses/${index}`);
  const snapshot = await get(dbRef);
  if (snapshot.exists()) {
    return snapshot.val();
  } else {
    throw new Error("Verse not available");
  }
};

const fetchVersesCount = async () => {
  const dbRef = ref(database, "verses");
  const snapshot = await get(dbRef);
  if (snapshot.exists()) {
    const data = snapshot.val();
    return data.length; // Correctly count the number of verses as it's an array
  } else {
    throw new Error("No data available");
  }
};

export const Main = () => {
  const [currentIndex, setCurrentIndex] = useState(null);
  const [likedVerses, setLikedVerses] = useState({});
  const [likes, setLikes] = useState(null);
  const paragraphRef = useRef(null);

  const {
    data: verse,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["verse", currentIndex],
    queryFn: () => fetchVerse(currentIndex),
    staleTime: 1000 * 60 * 5, // 5 minutes
    enabled: currentIndex !== null,
  });

  const { data: versesCount } = useQuery({
    queryKey: ["versesCount"],
    queryFn: fetchVersesCount,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  useEffect(() => {
    if (versesCount) {
      setCurrentIndex(versesCount - 1); // Set the initial index to the last verse
    }
  }, [versesCount]);

  useEffect(() => {
    if (verse) {
      setLikes(verse.likes); // Set initial likes
    }
  }, [verse]);

  useEffect(() => {
    if (paragraphRef.current && verse) {
      const paragraphLength = paragraphRef.current.innerText.length;
      paragraphRef.current.id =
        paragraphLength > 146
          ? "scaledown"
          : paragraphLength > 400
          ? "scaledown2"
          : "";
    }
  }, [verse]);

  useEffect(() => {
    if (verse) {
      const isLiked = sessionStorage.getItem(`liked_${verse.date}`) === "true";
      setLikedVerses((prevState) => ({
        ...prevState,
        [verse.date]: isLiked,
      }));
    }
  }, [verse]);

  const handleClick = (index, date) => {
    const isLiked = sessionStorage.getItem(`liked_${date}`) === "true";
    const newLikes = isLiked ? likes - 1 : likes + 1;
    setLikedVerses((prevState) => ({
      ...prevState,
      [date]: !isLiked,
    }));
    setLikes(newLikes); // Update the likes state immediately
    handleLikePress(index, date, isLiked);
    sessionStorage.setItem(`liked_${date}`, !isLiked);
  };

  const openAlert = () => {
    document.getElementById("my_modal_5").showModal();
  };

  const handleShareClick = () => {
    if (verse) {
      const urlToCopy = `${window.location.origin}/verse/${currentIndex}`;
      navigator.clipboard
        .writeText(urlToCopy)
        .then(() => {
          openAlert();
        })
        .catch((err) => {
          console.error("Failed to copy link: ", err);
        });
    }
  };

  const openNextPost = () => {
    setCurrentIndex((prev) => prev + 1);
  };

  const openPreviousPost = () => {
    setCurrentIndex((prev) => Math.max(prev - 1, 0));
  };

  if (error) return <ErrorPage text={error.message} />;

  return (
    <>
      <div className="quote-wrapper">
        <blockquote className="text">
          {isLoading ? (
            <span></span>
          ) : (
            <>
              <p ref={paragraphRef}>{verse?.text}</p>
              <footer>
                  - {Books[verse?.book] ?? 'Loading'}. {verse?.chapter ?? '#'}:{verse?.verse ?? ' &;'}
                <div className="icons">
                  <ShareBtn onClick={handleShareClick} />
                  <Alert />
                  <LikeBtn
                    style={{
                      color: likedVerses[verse?.date] ? "#FF00FF" : "white",
                      fontSize: likedVerses[verse?.date] ? "20px" : "17px",
                    }}
                    onClick={() => handleClick(currentIndex, verse?.date)}
                  />
                </div>
              </footer>
            </>
          )}
        </blockquote>
      </div>
      <Pagination
        currentIndex={currentIndex}
        verse={verse} // Pass the entire verse object
        maxIndex={versesCount - 1} // Pass the total number of
        onNext={openNextPost}
        onPrevious={openPreviousPost}
      />
      <Menu
        verse={verse}
        likes={likes}
        isClicked={likedVerses[verse?.date]}
        handleClick={() => handleClick(currentIndex, verse?.date)}
      />
    </>
  );
};
